import axios from "axios";
import { Message } from "element-ui";

//创建一个请求
const _axios = axios.create({
  baseURL: "https://np.zhihuianxian.com",
  timeout: 10000,
});

// 路由请求拦截
axios.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// 路由响应拦截
_axios.interceptors.response.use(
  (response) => {
    if (response.data.success === false) {
      return Message.error(response.data.errDesc);
    } else {
      return response.data;
    }
  },
  (error) => {
    return Promise.reject(error.response); // 返回接口返回的错误信息
  }
);

export default _axios;