<!-- 折线图图 -->
<template>
    <div ref="box" style="width:560px;height: 300px;"></div>
</template>
<script>
export default {
    name: "HelloEcharts",
    props: {
        zxtData: {
            type: Array,
            default: [],
        },
        datax: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            myChart: null
        };
    },
    watch: {
        zxtData: {
            handler(e, o) {
                if (e.length > 0) {
                    this.initEcharts(e)
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        initEcharts(e) {
            this.myChart = this.$echarts.init(this.$refs.box);
            let datas = e
            const option = {
                grad:{
                //     x:0,
                //     y:0,
                //     x2:0,
                //     y2:100
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}'
                },
                legend: {
                    left: 'center',
                    textStyle:{
                        color:'#fff'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.datax,
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//坐标值得具体的颜色
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff',//坐标轴的具体的颜色
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#fff',//坐标值得具体的颜色
                        }
                    },
                    splitLine: { //网格线
                        show: true,
                        lineStyle: {
                            color: '#12457b',
                            type: 'dashed'
                        }
                    },
                },
                series: [
                    {
                        name: datas[0].name,
                        data: datas[0].data,
                        type: 'line'
                    },
                    {
                        name: datas[1].name,
                        data: datas[1].data,
                        type: 'line'
                    },
                    {
                        name: datas[2].name,
                        data: datas[2].data,
                        type: 'line'
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
};
</script>
<style scoped>

</style>
