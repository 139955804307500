<template>
    <div>
        <el-header>
            <div style="font-size:36px;color: #fff;width: 100%;text-align: center;line-height: 90px;">{{ title }}</div>
            <!-- <el-button class="btn" @click="open" type="primary" icon="el-icon-setting" round>进入后台</el-button> -->
        </el-header>
        <el-main>
            <el-row>
                <el-col class="left" :span="8">
                    <div class="box-left">
                        <div class="left-top">
                            <div class="ltop-tit">
                                <div class="title-two">辽宁省农批市场数量：{{ npNum }}</div>
                                <div class="title-two">商户总数：{{ shNum }}</div>
                            </div>
                            <div style="display:flex;width: 560px;flex-wrap: wrap;padding: 20px 0 0 20px;">
                                <div @click="toxq(item.city_id)" class="cityBox" v-for="(item, index) in city">
                                    <div style="color:#fff;">{{ item.city_name }}</div>
                                    <div style="color:#1cfeff;">{{ item.num }}</div>
                                </div>
                                <div class="title-two" style="margin:10px 0 0 80px">溯源总数：{{ syzNum }}</div>
                            </div>
                        </div>
                        <div class="left-cen">
                            <div class="title-two source">溯源流向品种统计</div>
                            <BZT :bztData="bztData1"></BZT>
                            <!-- <ZZT :zztData="zztData"></ZZT> -->
                        </div>
                        <div class="left-bot">
                            <div class="title-two downstream">溯源流向</div>
                            <BZT :bztData="bztData2"></BZT>
                            <!-- <div style="display:flex;width: 560px;flex-wrap: wrap;padding-top: 20px; margin-left: -20px;">
                                <div class="cityBox" v-for="(item, index) in sylx">
                                    <div style="color:#fff;">{{ item.name }}</div>
                                    <div style="color:#1cfeff;">{{ item.num }}</div>
                                    <div style="color:#fff;">家</div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </el-col>
                <el-col class="mid" :span="8">
                    <div class="box-mid">
                        <div class="mid-top">
                            <div style="color:#031725;margin: 10px 0 ;display: flex;">
                                <div class="tNum">
                                    <div class="toNum"> 总商户数</div>
                                    <div class="tooNum" style="margin-left:20px">{{ zshNum }}</div>
                                </div>
                                <div class="tNum">
                                    <div class="toNum">溯源商户数</div>
                                    <div class="tooNum">{{ syNum }}</div>
                                </div>
                                <div class="tNum">
                                    <div class="toNum">当日上市数量(吨)</div>
                                    <div class="tooNum">{{ dayaNum }}</div>
                                </div>
                                <div class="tNum">
                                    <div class="toNum">累计上市数量(吨)</div>
                                    <div class="tooNum">{{ allNum }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mid-cen">
                            <div @click="toxq(210200000000)" class="city" style="position:absolute;top:309px;left: 98px;">朝阳
                            </div>
                            <div @click="toxq(210900000000)" class="city" style="position:absolute;top:215px;left: 271px;">
                                阜新</div>
                            <div @click="toxq(210100000000)" class="city" style="position:absolute;top:248px;left: 374px;">
                                沈阳</div>
                            <div @click="toxq(211200000000)" class="city" style="position:absolute;top:163px;left: 475px;">
                                铁岭</div>
                            <div @click="toxq(210400000000)" class="city" style="position:absolute;top:415px;left: 92px;">
                                葫芦岛</div>
                            <div @click="toxq(210700000000)" class="city" style="position:absolute;top:312px;left: 220px;">
                                锦州</div>
                            <div @click="toxq(210400000000)" class="city" style="position:absolute;top:263px;left: 514px;">
                                抚顺</div>
                            <div @click="toxq(211100000000)" class="city" style="position:absolute;top:347px;left: 275px;">
                                盘锦</div>
                            <div @click="toxq(210300000000)" class="city" style="position:absolute;top:401px;left: 352px;">
                                鞍山</div>
                            <div @click="toxq(211000000000)" class="city" style="position:absolute;top:338px;left: 388px;">
                                辽阳</div>
                            <div @click="toxq(210500000000)" class="city" style="position:absolute;top:338px;left: 449px;">
                                本溪</div>
                            <div @click="toxq(210800000000)" class="city" style="position:absolute;top:438px;left: 319px;">
                                营口</div>
                            <div @click="toxq(210600000000)" class="city" style="position:absolute;top:429px;left: 477px;">
                                丹东</div>
                            <div @click="toxq(210200000000)" class="city" style="position:absolute;top:529px;left: 282px;">
                                大连</div>
                            <img @click="toxq(210200000000)" class="city-image"
                                style="position:absolute;top: 200px;left: 0px;"
                                src="../assets/bigIndex/map/map_ChaoYang_default.png" alt="">
                            <img @click="toxq(210900000000)" class="city-image"
                                style="position:absolute;top: 151px;left: 202px;"
                                src="../assets/bigIndex/map/map_FuXin_default.png" alt="">
                            <img @click="toxq(210100000000)" class="city-image"
                                style="position:absolute;top: 129px;left: 325px;"
                                src="../assets/bigIndex/map/map_ShenYang_default.png" alt="">
                            <img @click="toxq(211200000000)" class="city-image"
                                style="position:absolute;top: 73px;left: 416px;"
                                src="../assets/bigIndex/map/map_TieLing_default.png" alt="">
                            <img @click="toxq(211400000000)" class="city-image"
                                style="position:absolute;top: 351px;left: 32px;"
                                src="../assets/bigIndex/map/map_HuLuDao_default.png" alt="">
                            <img @click="toxq(210700000000)" class="city-image"
                                style="position:absolute;top: 238px;left: 170px;"
                                src="../assets/bigIndex/map/map_JinZhou_default.png" alt="">
                            <img @click="toxq(210400000000)" class="city-image"
                                style="position:absolute;top: 192px;left: 437px;"
                                src="../assets/bigIndex/map/map_FuShun_default.png" alt="">
                            <img @click="toxq(211100000000)" class="city-image"
                                style="position:absolute;top: 322px;left: 249px;"
                                src="../assets/bigIndex/map/map_PanJin_default.png" alt="">
                            <img @click="toxq(210300000000)" class="city-image"
                                style="position:absolute;top: 310px;left: 301px;"
                                src="../assets/bigIndex/map/map_AnShan_default.png" alt="">
                            <img @click="toxq(211000000000)" class="city-image"
                                style="position:absolute;top: 304px;left: 340px;"
                                src="../assets/bigIndex/map/map_LiaoYang_default.png" alt="">
                            <img @click="toxq(210500000000)" class="city-image"
                                style="position:absolute;top: 305px;left: 428px;"
                                src="../assets/bigIndex/map/map_BenXi_default.png" alt="">
                            <img @click="toxq(210800000000)" class="city-image"
                                style="position:absolute;top: 384px;left: 283px;"
                                src="../assets/bigIndex/map/map_YingKou_default.png" alt="">
                            <img @click="toxq(210600000000)" class="city-image"
                                style="position:absolute;top: 356px;left: 411px;"
                                src="../assets/bigIndex/map/map_DanDong_default.png" alt="">
                            <img @click="toxq(210200000000)" class="city-image"
                                style="position:absolute;top: 475px;left: 197px;"
                                src="../assets/bigIndex/map/map_DaLian_default.png" alt="">
                        </div>
                        <div class="mid-bot"></div>
                    </div>
                </el-col>
                <el-col class="right" :span="8">
                    <div class="box-right">
                        <div class="right-top">
                            <div class="title-two vegetable">菜品量统计</div>
                            <ZXT :zxtData="zxtData" :datax="datax"></ZXT>
                        </div>
                        <div class="right-cen">
                            <div class="title-two unqualified">2022年不合格总数--各地区检测不合格占比</div>
                            <BZT :bztData="bztData3"></BZT>
                        </div>
                        <div class="title-two downstream">近三月来源方向（吨）</div>
                        <div class="right-bot">
                            <el-table :data="tableData" style="width: 97%;height: 82%;"
                                :header-cell-style="{ 'text-align': 'center', 'padding': '8px 0' }"
                                :cell-style="{ 'text-align': 'center', 'padding': '8px 0' }">
                                <el-table-column prop="date" label="" width="52">
                                </el-table-column>
                                <el-table-column label="省内">
                                    <el-table-column prop="sy" label="沈阳" width="70">
                                    </el-table-column>
                                    <el-table-column prop="dl" label="大连" width="70">
                                    </el-table-column>
                                    <el-table-column prop="as" label="鞍山" width="70">
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column label="省外">
                                    <el-table-column prop="cc" label="长春" width="70">
                                    </el-table-column>
                                    <el-table-column prop="heb" label="哈尔滨" width="70">
                                    </el-table-column>
                                    <el-table-column prop="lyg" label="连云港" width="70">
                                    </el-table-column>
                                </el-table-column>
                                <el-table-column prop="total" label="合计" width="70"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-main>
    </div>
</template>
  
<script>
import { getData } from '@/api/getData'
import moment from "moment";
import ZZT from '@/components/echarts/zzt.vue'
import ZXT from '@/components/echarts/zxt.vue'
import BZT from '@/components/echarts/bzt.vue'
export default {
    components: {
        ZZT,
        ZXT,
        BZT
    },
    data() {
        return {
            title: '辽宁省食用农产品质量安全追溯大数据分析平台',
            //左一
            city: [],
            npNum: 0,
            shNum: 0,
            syzNum: 0,
            //左二
            // zztData: {
            //     datax: ['学校食堂', '中央厨房', '单位食堂', '大型餐饮', '大型超市'],
            //     datay: [280, 554, 620, 380, 360]
            // },
            bztData1: [

            ],
            bztData2: [

            ],
            //左三
            // sylx: [
            //     { name: '学校食堂', num: 23 },
            //     { name: '中央厨房', num: 45 },
            //     { name: '单位食堂', num: 56 },
            //     { name: '大型餐饮', num: 56 },
            //     { name: '大型超市', num: 56 },
            // ],
            //中一
            zshNum: 0,
            syNum: 0,
            dayaNum: 0,
            allNum: 0,
            //右一
            zxtData: [],
            datax: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            //右二
            bztData3: [],
            //右三
            tableData: [{
                date: '芹菜',
                sy: 128,
                dl: 116,
                as: 129,
                cc: 79,
                heb: 59,
                lyg: 67,
                total: 578
            }, {
                date: '韭菜',
                sy: 179,
                dl: 152,
                as: 59,
                cc: 85,
                heb: 76,
                lyg: 45,
                total: 596
            }, {
                date: '豇豆',
                sy: 118,
                dl: 143,
                as: 96,
                cc: 85,
                heb: 55,
                lyg: 69,
                total: 566
            },],
            //给后端传送的测试数据
            cesiid: '',
            // cesiid: '6E285E75106F42C593C62B80A26B97AA',
        }
    },
    methods: {
        //初始函数
        init() {
            //标题解码名称
            // let a = decodeURI(this.$store.state.nameUrl);
            // this.nameUrl = decodeURI(a);
            // this.cesiid = this.nameUrl
            // console.log(this.nameUrl);
            //获取数据
            this.toData()
        },
        //获取数据
        toData() {
            //调接口，处理数据
            this.proData()
            //获取整点时间
            var nowTemp = new Date().getTime() //获取当前时间戳
            var nexthoursTemp = new Date(moment().add(1, 'hours').format('YYYY-MM-DD H:mm:ss').substr(0, 14) + ':00:00').getTime() //获取下个小时整点的时间戳
            var residueTemp = nexthoursTemp - nowTemp //当前距离下个小时整点的时间戳
            //执行定时任务
            setTimeout(() => {
                this.proData()
                //刷新方法
                //次时0点 执行下个小时整点刷新
                setInterval(() => {
                    this.proData()
                    //刷新方法
                }, 1000 * 60 * 60);
            }, residueTemp)
        },
        //调接口，处理数据
        proData() {
            getData(this.cesiid)
                .then((res) => {
                    console.log('----------', res);
                    //左一
                    this.npNum = res.marketNum
                    this.shNum = res.sellerNumTotal
                    this.syzNum = res.sellerNumTrace
                    this.city = res.cityMarketNum
                    //中一
                    this.zshNum = res.sellerNumTotal
                    this.syNum = res.sellerNumTrace
                    this.dayaNum = res.entryNumToday.toFixed(1)
                    this.allNum = res.entryNumTotal.toFixed()
                    //右一
                    this.zxtData = res.monthProductQuantities
                    console.log(this.zxtData);
                    //右二
                    // console.log('bingzhuangtu', res.cityUnqualifieds);
                    // this.bztData3 = res.cityUnqualifieds.map((item) => {
                    //     return {
                    //         name: item.cityName,
                    //         value: item.unqualified
                    //     }
                    // })
                    let arr3 = [{ name: '沈阳', value: 4 }, { name: '大连', value: 2 }, { name: '盘锦', value: 4 }, { name: '本溪', value: 3 }, { name: '鞍山', value: 2 }, { name: '营口', value: 3 }, { name: '丹东', value: 3 }]
                    this.bztData3 = arr3
                    let arr1 = [{ name: '豇豆', value: 700 }, { name: '韭菜', value: 480 }, { name: '芹菜', value: 540 }]
                    this.bztData1 = arr1
                    let arr2 = [{ name: '学校食堂', value: 600 }, { name: '单位食堂', value: 480 }, { name: '餐饮', value: 100 }, { name: '超市', value: 320 }, { name: '中央厨房', value: 240 },]
                    this.bztData2 = arr2
                    console.log(this.bztData)
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        //跳转到二级页面
        toxq(item) {
            console.log(item);
            // this.$router.replace('/city')
        }
    },
    mounted() {
        this.init()
    }
}
</script>
  
<style scoped>
::v-deep.el-header {
    height: 90px !important;
    padding: 0;
    background: url(../assets/bigIndex/title.png) no-repeat;
}

.title-two {
    color: #fff;
    font-size: 20px;
}

.left {
    margin-right: -20px;
}

.left-top {
    width: 560px;
    height: 292px;
    background: url(../assets/bigIndex/bg_city_outside.png) no-repeat;
    /* padding: 10px; */
    padding: 10px 0 0 0;
}

.ltop-tit {
    display: flex;
    justify-content: space-around;
    background: url(../assets/bigIndex/bg_city_line.png) no-repeat;
    background-position: bottom;
    line-height: 40px;
}

.cityBox {
    width: 99px;
    height: 49px;
    background: url(../assets/bigIndex/bg_city.png) no-repeat;
    display: flex;
    margin: 0 15px 0 0;
    line-height: 50px;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    cursor: pointer;
}

.source {
    background: url(../assets/bigIndex/bg_source_title.png) no-repeat;
    background-position: bottom left;
    width: 160px;
    height: 42px;
    line-height: 42px;
    margin: 0 0 -20px 30px;
}

.left-bot {
    width: 560px;
    height: 245px;
    /* background: url(../assets/bigIndex/bg_source_outside.png) no-repeat; */
    /* padding: 20px 0 0 40px */
}

.mid {
    margin-right: 20px;
}

.mid-top {
    width: 620px;
    height: 163px;
    background: url(../assets/bigIndex/bg_middle.png) no-repeat;
}

.tNum {
    margin: 20px 0 0 0;
}

.toNum {
    /* margin-top: 40px; */
    background: #ffab19;
    border-radius: 6px;
    line-height: 50px;
    margin: 0 0 0 17px;
    font-size: 20px;
    padding: 0 6px;
}

.tooNum {
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
    color: #6dc0ff;
}

.mid-cen {
    width: 641px;
    height: 617px;
    position: relative;
    background: url(../assets/bigIndex/map_bottom_pic.png) no-repeat -13px 58px;
}

.city {
    color: #fff;
    z-index: 100;
    cursor: pointer;
}

.city:hover {
    color: #007ffe;
}

.city-image:hover {
    opacity: 0.5;
    /* background: red; */
}

.vegetable {
    background: url(../assets/bigIndex/bg_vegetable_title.png) no-repeat;
    background-position: bottom left;
    width: 104px;
    height: 42px;
    line-height: 42px;
    margin: 0 0 -20px 30px;
}

.unqualified {
    background: url(../assets/bigIndex/bg_unqualified_title.png) no-repeat;
    background-position: bottom left;
    width: 390px;
    height: 42px;
    line-height: 42px;
    margin: 0 0 -20px 30px;
}

.right-bot {
    width: 560px;
    height: 245px;
    background: url(../assets/bigIndex/bg_downstream_outside.png) no-repeat;
    padding: 9px 0 0 8px;
}

.downstream {
    background: url(../assets/bigIndex/bg_downstream_title.png) no-repeat;
    background-position: bottom left;
    width: 240px;
    height: 42px;
    line-height: 42px;
    margin: 0 0 10px 30px;
}

.right-bot ::v-deep .el-table,
.el-table__expanded-cell {
    background-color: transparent;
}

.right-bot ::v-deep .el-table th {
    background-color: transparent !important;
    border-bottom: 1px solid #396aa4;
    color: #6dc0ff;
}

.right-bot ::v-deep .el-table tr {
    background-color: transparent !important;
}

.right-bot ::v-deep .el-table--enable-row-transition .el-table__body td,
::v-deep.el-table .cell {
    background-color: transparent;
    color: #6dc0ff;
    border: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

::v-deep.el-table th>.cell {
    color: #6dc0ff;
    font-weight: 700;
}

.el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}

.el-table ::v-deep tbody tr:hover>td {
    background-color: transparent !important
}
</style>
  