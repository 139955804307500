import https from "./axios";

export function getData(id) {
  return https({
    url: "/api/supervisecover?id="+id,
    method: "get",
    // data:data
  });
}

export function menuList(user) {
  return https({
    url: "",
    method: "post",
    user,
  });
}
