<!-- 饼状图 -->
<template>
    <div ref="box" style="width:560px;height: 280px;"></div>
</template>
<script>

export default {
    name: "HelloEcharts",
    props: {
        bztData: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            myChart: null,
        };
    },
    watch: {
        bztData: {
            handler(e, o) {
                if (e.length > 0) {
                    this.initEcharts(e)
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        initEcharts(e) {
            console.log('传过来的诗句', this.bztData,e);
            this.myChart = this.$echarts.init(this.$refs.box);
            let datas = e;
            const option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    x: 'left',
                    y: 'center',
                    textStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: datas,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是重点
                                color: function (params) {
                                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                    var colorList = ['#007ffe', '#00e3ff', '#e9518e', '#ff8c34', '#f66663', '#ffea23', '#1cb2ff'];
                                    return colorList[params.dataIndex]
                                }
                            }
                        },
                        label: {
                            normal: {
                                color: '#fff',
                                formatter: '{b}:({d}%)' 
                            }
                        }
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
    mounted(){

    }
};
</script>
<style scoped>

</style>
