import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index";
// import Index from "../views/JGxt/index";


//防止多次点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
   {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/city",
    name: "city",
    component:()=> import('@/views/city'),
  }
 
];

const router = new VueRouter({
  routes,
});

export default router;
