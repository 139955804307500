<!-- 纵向柱状图 -->
<template>
    <div id="box1" style="width:560px;height: 360px;"></div>
</template>
<script>
export default {
    name: "HelloEcharts",
    props: {
        zztData: {
            type: Object,
            default: {},
        }
    },
    data() {
        return {
            myChart: null
        };
    },
    mounted() {
        // console.log('传过来的诗句', this.zxtopData);
        this.myChart = this.$echarts.init(document.getElementById('box1'));
        const option = {
            xAxis:
            {
                type: 'category',
                data: this.zztData.datax,
                axisLabel: {
                    textStyle: {
                        color: '#fff',//坐标值得具体的颜色
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff',//坐标轴的具体的颜色
                    }
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#fff',//坐标值得具体的颜色
                    }
                },
                splitLine: { //网格线
                    show: true,
                    lineStyle: {
                        color: '#12457b',
                        type: 'dashed'
                    }
                },
                // interval: 200,//间隔刻度
            },
            series: [
                {
                    data: this.zztData.datay,
                    type: 'bar',
                    barWidth: '30%',
                    itemStyle: {
                        normal: {
                            //这里是重点
                            color: function (params) {
                                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                var colorList = ['#509ff4', '#43d2bc', '#7c59cf', '#fe7698', '#f77c38'];
                                return colorList[params.dataIndex]
                            }
                        }
                    },
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            color: '#fff'
                        }
                    }
                    // grid:{
                    //     left:'400'
                    // }
                }
            ]
        };
        this.myChart.setOption(option);

    },
};
</script>
<style scoped>

</style>
